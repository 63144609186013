import React from 'react';

import {graphql} from 'gatsby';

import { Row, Col } from 'antd';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import {Page} from '../contracts/page';

import {GatsbyImage} from 'gatsby-plugin-image';
import {IGatsbyImageData} from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import scrollTo from 'gatsby-plugin-smoothscroll';

import '../components/Booking/BookingForm.scss';

import ColourBlock from '../components/ColourBlock';
import ContentBlockHtml from '../components/ContentBlock';
import Cta from '../components/Ctas';
import FaqBlock from '../components/Faqs';
import Hires from '../components/HireTypes';
import ReviewsBlock from '../components/Reviews';

import '../styles/home.scss';

export interface Props {
	data: {
		wpPage: Page;
	};
	location: Location;
}

export const IndexPage = (props: Props) => {
	const page = props.data.wpPage;
	const fluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.heroBanner &&
		page.heroBanner.bannerImage &&
		page.heroBanner.bannerImage.localFile &&
		page.heroBanner.bannerImage.localFile.childImageSharp &&
		page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData
	) ? page.heroBanner.bannerImage.localFile.childImageSharp.gatsbyImageData : null;
	const bannerFluid: IGatsbyImageData | null = (
		props.data &&
		page &&
		page.bannerContentBlock &&
		page.bannerContentBlock.bannerContentImage &&
		page.bannerContentBlock.bannerContentImage.localFile &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp &&
		page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData
	) ? page.bannerContentBlock.bannerContentImage.localFile.childImageSharp.gatsbyImageData : null;
	const settings    = {
		dots: true,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					centerMode: true
				}
			}
		]
	};
	const textColour = page.heroBanner.bannerTextColour;
	return (
		<Layout location={props.location}>
			<SEO title={page.seo.title} description={page.seo.metaDesc} />
			<Row type="flex">
				<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} id="primary" className="content-area">
					<div className="home">
						<div className="top-banner">
							{(page.heroBanner && page.heroBanner.showBanner === 'yes' && fluid !== null) && (
								<Row type="flex" className="c_hero-banner c_hero-banner--full-width full-width-row">
									<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
										<GatsbyImage loading={'eager'} image={fluid} alt={page.heroBanner.bannerTitle} title={page.heroBanner.bannerTitle} />
										<div className="c_hero-banner__content">
											<h1 className={'text-' + textColour}>{page.heroBanner.bannerTitle}</h1>
											<div className={'text-' + textColour}>
												<div className={'text-' + textColour} dangerouslySetInnerHTML={{__html: page.heroBanner.bannerContent}}/>
												<a onClick={() => scrollTo('#hireCategories')}>View our Fleet</a>
											</div>
										</div>
									</Col>
								</Row>
							)}
							{(page.bookNowBlock && page.bookNowBlock.showBookNowBlock === 'yes') ? (
								<iframe sandbox="allow-top-navigation allow-scripts allow-forms allow-same-origin" className={'c_booking ant-row'} src={'https://dmg.bookmyhire.co.uk/?i=iframe'} width={'65%'} height={'490px'}/>
							) : ''}
						</div>
						{(page.hireTypes && page.hireTypes.hireType && page.hireTypes.hireType.length) ? (
							<Hires location={props.location} hireTypes={page.hireTypes}/>
						) : ''}
						{(page.ctas && page.ctas.ctas && page.ctas.ctas.length) ? (
							<Cta location={props.location} ctas={page.ctas} welfare={false}/>
						) : ''}
						{(page.bannerContentBlock && page.bannerContentBlock.showBannerContentBlock === 'yes' && bannerFluid !== null) ? (
							<ColourBlock location={props.location} bannerContentBlock={page.bannerContentBlock} bannerFluid={bannerFluid}/>
						) : ''}
						{(page.reviews.showReviews === 'yes' && page.reviews && page.reviews.reviews && page.reviews.reviews.length) ? (
							<ReviewsBlock location={props.location} reviews={page.reviews} settings={settings}/>
						) : ''}
						{(page.faqs.showFaqs === 'yes' && page.faqs && page.faqs.faqs && page.faqs.faqs.length) ? (
							<FaqBlock location={props.location} showHeading={true} faqs={page.faqs} bgWhite={false}/>
						) : ''}
						{(page.contentBlock && page.contentBlock.showContentBlock === 'yes') ? (
							<ContentBlockHtml location={props.location} contentBlock={page.contentBlock}/>
						) : ''}
					</div>
				</Col>
			</Row>
		</Layout>
	);
};

export default IndexPage;

export const query = graphql`
	query {
      wpPage(isFrontPage: {eq: true}) {
        bookNowBlock {
          showBookNowBlock
        }
		bannerContentBlock {
		  bannerContentBackgroundColour
		  bannerContentContent
		  bannerContentCtaLink
		  bannerContentCtaText
		  bannerContentShowCta
		  bannerContentSubtitle
		  bannerContentSubtitleColour
		  bannerContentTitle
		  bannerContentTitleColour
		  fieldGroupName
		  showBannerContentBlock
		  bannerContentImage {
		    altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		contentBlock {
		  contentContent
		  contentSubtitle
		  contentTitle
		  fieldGroupName
		  showContentBlock
		}
		ctas {
		  ctas {
			fieldGroupName
			ctaTitle
			ctaIcon {
			  altText
			  localFile {
			    publicURL
			    childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
			    }
			  }
		    }
			ctaLink {
			  target
			  title
			  url
			}
		  }
		  fieldGroupName
		}
		faqs {
		  faqs {
			question
			fieldGroupName
			answer
		  }
		  showFaqs
		  fieldGroupName
		}
		heroBanner {
		  bannerContent
		  bannerLayout
		  bannerTextColour
		  fieldGroupName
		  bannerTitle
		  showBanner
		  bannerImage {
			altText
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
		hireTypes {
		  fieldGroupName
		  hireType {
			fieldGroupName
			hireContent
			typeOfHire
			hireLink {
			  ... on WpPage {
				link
			  }
			}
			hireImage {
			  altText
			  caption
			  description
			  fileSize
			  localFile {
				childImageSharp {
				  gatsbyImageData(
                    layout: CONSTRAINED
                    placeholder: BLURRED
                    quality: 100
                    webpOptions: {quality: 100}
                    pngOptions: {quality: 100}
                    jpgOptions: {quality: 100}
                  )
				}
			  }
			}
		  }
		}
		reviews {
		  showReviews
		  reviews {
			reviewContent
			reviewName
			reviewStars
		  }
		}
		seo {
		  metaDesc
		  metaKeywords
		  metaRobotsNoindex
		  metaRobotsNofollow
		  opengraphAuthor
		  opengraphDescription
		  opengraphModifiedTime
		  opengraphPublishedTime
		  opengraphPublisher
		  opengraphSiteName
		  opengraphTitle
		  opengraphType
		  opengraphUrl
		  readingTime
		  title
		  twitterTitle
		  schema {
			articleType
			pageType
			raw
		  }
		  twitterDescription
		  twitterImage {
			localFile {
			  childImageSharp {
				gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: BLURRED
                  quality: 100
                  webpOptions: {quality: 100}
                  pngOptions: {quality: 100}
                  jpgOptions: {quality: 100}
                )
			  }
			}
		  }
		}
      }
    }
`;
